import { type Cell } from 'react-table';
import { ContrainedGroupTags } from '@presentation/GroupTag';
import type { IFilesTable } from './interfaces';
import styles from './style/Files.Table.Cells.css';

export const TagsCell = ({ row: { original: data } }: Cell<IFilesTable.Item>) => {
  if (!data.tags?.length) return null;

  return (
    <ContrainedGroupTags
      className={styles.cellTags}
      tags={data.tags}
      maxTags={1} />
  );
};