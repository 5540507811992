import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import type { ProjectOverviewFormState } from '@containers/GroupProject/interfaces';
import * as enums from '@enums';
import { FormButtons } from '@presentation';
import { cx } from '@utils';
import type { Project } from '@/types';
import { Input, NumberInput } from '@/components/Input';
import { GoalInput } from '@/components/GroupProject/GoalInput';
import { ProjectFrequencyDropdown } from '@/components/GroupProject/ProjectFrequencyDropdown';
import { useProjectDescriptionInput } from '@/components/GroupProject/hooks/useProjectDescriptionInput';
import { NetSuiteProjects } from '$admin/components/Autocomplete';
import * as SetupFields from '$admin/Project.Creation/Field.Setup';
import * as utils from './utils';
import styles from './style.css';

const mapState = (state: Store.State) => state.group.features;

type Props = {
  account: Descriptor;
  frequency: enums.ProjectFrequency;
  goal: number;
  item: Project;
  netsuite: {
    id: number;
    identifier: string;
    name: string;
  };
  purchaseOrder: string;
  onCancel: () => void;
  onSubmit: (data: ProjectOverviewFormState) => unknown;
};

const ProjectOverviewForm = (props: Props) => {
  const [form, setFormState] = useState<ProjectOverviewFormState>({
    accountId: props.account?.id ?? null,
    clientName: props.item.clientName ?? '',
    description: props.item.description,
    frequency: props.frequency,
    goal: props.goal,
    name: props.item.rawName,
    netsuite: props.netsuite,
    purchaseOrder: props.purchaseOrder,
  });
  const groupFeatures = useSelector(mapState);

  const [ProjectDescriptionInput, validateDescription] = useProjectDescriptionInput();

  function handleChangeGoal(value: number) {
    setFormState({ ...form, goal: value });
  }

  function handleChangeText(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setFormState({ ...form, [e.target.name]: e.target.value });
  }

  function handleSubmit() {
    props.onSubmit(form);
  }

  function handleChangeFrequency(frequency: enums.ProjectFrequency) {
    setFormState({ ...form, frequency });
  }

  const canSubmit = useMemo(() => {
    return form.description
      && validateDescription(form.description)
      && form.name
      && ((!props.purchaseOrder && !form.purchaseOrder) || validatePurchaseOrder(form.purchaseOrder))
      && !Number.isNaN(parseInt(form.goal as unknown as string))
      && (form.description !== props.item.description ||
        form.accountId !== props.account?.id ||
        form.purchaseOrder !== props.purchaseOrder ||
        form.netsuite?.id !== props.netsuite?.id ||
        form.clientName !== props.item.clientName ||
        form.name !== props.item.name ||
        form.goal !== props.goal ||
        form.frequency !== props.frequency);
  }, [form.description, form.name, form.purchaseOrder, form.goal, form.accountId, form.netsuite?.id, form.clientName, form.frequency, validateDescription, props.purchaseOrder, props.item.description, props.item.clientName, props.item.name, props.account?.id, props.netsuite?.id, props.goal, props.frequency]);

  const frequencies = useMemo(() => {
    return enums.utils.ProjectFrequency.values()
      .filter(f => f !== enums.ProjectFrequency.None ||
        props.frequency === enums.ProjectFrequency.None);
  }, [props.frequency]);

  const includeFrequency = useMemo(() => {
    return utils.includeFrequency(props.item, groupFeatures);
  }, [
    groupFeatures,
    props.item,
  ]);

  const query = useQuery({
    queryKey: [`get:groups/search`],
    queryFn: api.groups.fetchAccounts,
  });

  const accounts = useMemo(() => {
    const items = query.data?.items ?? [];
    return {
      autofill: items.map(x => ({
        code: x.attributes?.code,
        id: x.id,
        name: x.name,
      })),
      options: items,
    };
  }, [query.data]);

  return (
    <>
      <div className={styles.body}>
        <div className={styles.row}>
          <div className={styles.label}>Project Code</div>
          <NetSuiteProjects
            onSelect={item => setFormState(state => ({ ...state, netsuite: item }))}
            selected={form.netsuite} />
        </div>
        <div className={styles.row}>
          <div className={styles.label}>PMR Platform Fee Purchase Order</div>
          <NumberInput
            allowLeadingZeros
            decimalScale={0}
            maxLength={5}
            minLength={5}
            onValueChange={item => setFormState(state => ({ ...state, purchaseOrder: item.value || null }))}
            value={form.purchaseOrder} />
        </div>
        <div className={styles.row}>
          <div className={styles.label}>Respondent Title</div>
          <div className={styles.field}>
            <Input
              className={styles.input}
              name="name"
              onChange={handleChangeText}
              type="text"
              value={form.name} />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>Client Title</div>
          <div className={styles.field}>
            <Input
              className={styles.input}
              name="clientName"
              onChange={handleChangeText}
              type="text"
              value={form.clientName} />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>Client Account</div>
          <div className={styles.field}>
            <SetupFields.SelectSearch
              clearable
              name="accountId"
              onChange={x => setFormState(state => ({ ...state, accountId: x?.id || null }))}
              options={accounts.options}
              placeholder="Client Account"
              value={form.accountId ?? undefined} />
          </div>
        </div>
        {includeFrequency &&
          <div className={styles.row}>
            <div className={styles.label}>Frequency</div>
            <div className={cx(styles.field, styles.frequency)}>
              <ProjectFrequencyDropdown
                items={frequencies}
                onSelect={handleChangeFrequency}
                value={form.frequency} />
            </div>
          </div>
        }
        <div className={styles.row}>
          <div className={styles.label}>Description</div>
          <div className={styles.field}>
            <div className={styles.textblock}>
              <ProjectDescriptionInput
                className={styles.description}
                onChange={handleChangeText}
                value={form.description} />
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.label}>Goal</div>
          <div className={cx(styles.field, styles.goal)}>
            <GoalInput
              onChange={handleChangeGoal}
              type={props.item.projectType}
              value={form.goal} />
          </div>
        </div>
      </div>

      <FormButtons
        disabled={!canSubmit}
        onCancel={props.onCancel}
        onSubmit={handleSubmit} />
    </>
  );
};

export { ProjectOverviewForm };
export default ProjectOverviewForm;

function validatePurchaseOrder(val: string) {
  const arr = (val || '').split('');
  const digits = arr.every(x => Number.isInteger(parseInt(x)));

  const valid = digits && arr.length === 5;

  return valid;
}