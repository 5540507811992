import type { AxiosResponse } from 'axios';
import axios from 'axios';
import { safeJsonDate } from '@utils';
import type { Projects } from './interfaces';

const metricsHttp = axios.create({
  baseURL: process.env.BACKEND_BASE_URL,
  withCredentials: true,
});

const getProjectSurveyMetrics = (data: Projects.GetSurveyMetrics.Request) => {
  return metricsHttp.get<Projects.GetSurveyMetrics.Response<string>>(`/projects/${data.projectId}/survey-metrics${data.surveyId ? `/${data.surveyId}` : ''}`)
    .then(res => ({
      ...res.data,
      project: {
        ...res.data.project,
        createdOn: safeJsonDate(res.data.project.createdOn),
      },
    }));
};

const downloadProjectSurveyResponses = (data: Projects.DownloadSurveyMetricResponses.Request) => {
  return metricsHttp.get(`/projects/${data.projectId}/survey-metrics/${data.surveyId}/download`, { responseType: 'blob' })
    .then((response: AxiosResponse<Blob>) => {
      const filename = new RegExp(/filename="(.+)"/).exec(response.headers['content-disposition'] as string);
      return {
        blob: response.data,
        filename: filename.length ? filename[1] : null,
      };
    });
};

const getProjectSurveyMetricsAuth = (data: Projects.FetchSurveyMetricsAuth.Request) => {
  return metricsHttp.get<Projects.FetchSurveyMetricsAuth.Response>(`/projects/${data.projectId}/survey-metrics/auth`)
    .then(res => res.data);
};

const projectSurveyMetricsAuth = ({ projectId, ...body }: Projects.SurveyMetricsAuth.Request) => {
  return metricsHttp.post<Projects.SurveyMetricsAuth.Response>(`/projects/${projectId}/survey-metrics/auth`, body);
};

export const surveyMetrics = {
  auth: {
    get: getProjectSurveyMetricsAuth,
    post: projectSurveyMetricsAuth,
  },
  get: getProjectSurveyMetrics,
  downloadResponses: downloadProjectSurveyResponses,
};