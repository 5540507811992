import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { pathname } from '@consts';
import { useFetchProjectMetrics } from '@utils/api';
import * as $metrics from '@api/projects.survey.metrics';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import { Button } from '@/components/Button';
import { ProjectProgressBar, useProjectReportModal } from '@/components/GroupProject';
import { useDownloader } from '@/utils';
import Toast from '@/components/Toast';
import { useProjectSurveyMetrics } from './hooks/useSurveyMetrics';
import { AdvancedQuotaProgress } from './AdvancedQuotaProgress';
import { BasicQuotasProgressTable } from './BasicQuotasTable';
import { SurveyProgressTable } from './ProgressTable';
import { SurveyTerminationsTable } from './TerminationsTable';
import { formatAvgDuration } from './utils';
import { useSurveyMonitoringPasswordRequired } from './Context';
import { useSurveyMonitoringAccessModal } from './AccessModal';
import styles from './style/SurveyMonitoring.css';
import { ButtonActivityIndicator } from '@/presentation';

type Props = IProjectId & Partial<ISurveyId>;

export const SurveyMonitoring = (props: Props) => {
  const [passwordRequired, setPasswordRequired] = useSurveyMonitoringPasswordRequired();
  const projectMetrics = useFetchProjectMetrics({ projectId: props.projectId }, {
    enabled: passwordRequired === false,
  });
  const history = useHistory();
  const surveyMetrics = useProjectSurveyMetrics({ projectId: props.projectId, surveyId: props.surveyId }, {
    onError: e => {
      if (e.response.status === 403) {
        setPasswordRequired(true);
      } else {
        history.push(pathname.Home);
      }
    },
    onSuccess: () => {
      setPasswordRequired(false);
    },
    retry: false,
  });

  const downloader = useDownloader();

  const downloadResponsesMutation = useMutation({
    mutationFn: () => {
      if (!props.surveyId) throw new Error();

      return $metrics.surveyMetrics.downloadResponses({ projectId: props.projectId, surveyId: props.surveyId }).then(downloader);
    },
    onError: () => Toast.error({ title: 'There was an error downloading the responses' }),
  });

  const [toggleGrantAccessModal, GrantAccessModal] = useSurveyMonitoringAccessModal();
  const [toggleReportModal, ReportModal] = useProjectReportModal();

  //Don't render if passwordRequired is still null, there's a render betwen useProjectSurveyMetrics finishing loading but before it calls setPasswordRequired
  if (surveyMetrics.isInitialLoading || projectMetrics.isInitialLoading || passwordRequired === null) return <ActivityIndicator show />;

  const creator = `${surveyMetrics.data.project.creator.firstName} ${surveyMetrics.data.project.creator.lastName}`;
  const createdOn = format(surveyMetrics.data.project.createdOn, 'MM/dd/yyyy');

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>

        <div className={styles.header}>
          <div className={styles.details}>
            <h1 className={styles.name}>{surveyMetrics.data.project.name}</h1>
            <div className={styles.survey}>{surveyMetrics.data.survey.name}</div>
            <div className={styles.age}>Created by {creator} on {createdOn}</div>
          </div>
          <div className={styles.actions}>
            {surveyMetrics.data.isDashboardPublic &&
              <>
                <Button
                  className={styles.grantAccess}
                  variant="brick"
                  onClick={toggleGrantAccessModal}>
                  Grant Access
                </Button>
                <GrantAccessModal
                  project={{
                    id: props.projectId,
                  }} />
              </>
            }
            <ButtonActivityIndicator loading={downloadResponsesMutation.isLoading} onClick={() => downloadResponsesMutation.mutate()}>Download Responses</ButtonActivityIndicator>

            <ReportModal
              project={surveyMetrics.data.project} />
          </div>
        </div>

        <ProjectProgressBar
          className={styles.progress}
          metrics={projectMetrics.data}
          project={surveyMetrics.data.project} />

        <div>
          <div className={styles.title}>
            Avg Duration: <span className={styles.subtitle}>{formatAvgDuration(surveyMetrics.data.stats.avgDuration)}</span>
          </div>
        </div>

        <div className={styles.topRow}>
          <div className={styles.respondents}>
            <div className={styles.title}>
              Respondents <span className={styles.subtitle}>({projectMetrics.data.surveys.totalCount})</span>
            </div>
            <SurveyProgressTable
              metrics={projectMetrics.data} />
          </div>
          <div className={styles.terminations}>
            <div className={styles.title}>Terminations</div>
            <SurveyTerminationsTable
              items={surveyMetrics.data.terminations} />
          </div>
        </div>

        {!!surveyMetrics.data.progress.basic.length &&
          <div className={styles.basic}>
            <div className={styles.title}>Basic Quotas</div>
            <BasicQuotasProgressTable
              items={surveyMetrics.data.progress.basic} />
          </div>
        }

        {!!surveyMetrics.data.progress.advanced.length &&
          <div className={styles.advanced}>
            {/* <div className={styles.title}>Advanced Quotas</div> */}
            {surveyMetrics.data.progress.advanced.map((m, i) => (
              <AdvancedQuotaProgress
                key={i}
                item={m} />
            ))}
          </div>
        }

      </div>
    </div>
  );
};