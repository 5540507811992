import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import * as api from '@api';
import type { Projects } from '@api/interfaces';

type Data = Projects.GetSurveyMetrics.Response;
type QueryKey = [string, number, number];

export const useProjectSurveyMetrics = (
  { projectId, surveyId }: IProjectId & Partial<ISurveyId>,
  options?: UseQueryOptions<Data, AxiosError, Data, QueryKey>
) => {

  return useQuery({
    queryKey: ['fetch-project-survey-metrics', projectId, surveyId],
    queryFn: ({ queryKey }) => {
      const projectId = queryKey[1] as number;
      const surveyId = queryKey[2] as number;

      return api.projects.surveys.metrics.get({
        projectId, surveyId,
      });
    },
    ...options,
  });
};