import type { AxiosError } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import type { ProjectMetrics } from '@/types';

type Data = ProjectMetrics.Metrics;
type QueryKey = [string, number];

const useFetchProjectMetrics = (
  { projectId }: IProjectId,
  options?: UseQueryOptions<Data, AxiosError, Data, QueryKey>
) => {

  return useQuery({
    queryKey: fetchProjectMetricsQueryKey(projectId),
    queryFn: async ({ queryKey }) => {
      const projectId = queryKey[1] as number;

      return api.projects.getProjectMetrics({
        projectId,
      }).then(res => res.data.metrics);
    },
    ...options,
  });
};

export function fetchProjectMetricsQueryKey(projectId: number) {
  return ['fetch-project-metrics', projectId];
}

export { useFetchProjectMetrics };
export default useFetchProjectMetrics;